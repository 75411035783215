import {
  SET_DISHES,
  SET_CURRENT_DISH,
  DELETE_DISH,
} from '../actions/dish';

let initialState = {
  dishesData: [],
  currentDish: null,
};

const dish = (state = initialState, action) => {
  switch (action.type) {
    case SET_DISHES:
      return {
        ...state,
        dishesData: action.payload,
      };

    case SET_CURRENT_DISH:
      return {
        ...state,
        currentDish: action.payload,
      };

    case DELETE_DISH: {
      const { dishesData } = state;
      const newDishData = dishesData.filter((dishData) => {
        return dishData.id !== action.payload;
      });

      return {
        ...state,
        dishesData: newDishData,
      };
    }

    default:
      return state;
  }
};

export default dish;
