import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';

import { addIngredient, updateIngredient } from '../../actions/ingredient';
import { unitTypes, UNIT_TYPE_WITHOUT_AMOUNT } from '../../constants/unitTypes';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import AlternativesSelect from '../IngredientsSelect/AlternativesSelect';
import styles from './IngredientModal.module.css';

const IngredientModal = (props) => {
  const { 
    initialValue, 
    isOpen, 
    onClose,
    addIngredient,
    updateIngredient,
    ingredientsData,
   } = props;

  const [name, setName] = useState('');  
  const [unitType, setUnitType] = useState('');
  const [calories, setCalories] = useState('');
  const [fat, setFat] = useState('');
  const [protein, setProtein] = useState('');
  const [carbohydrates, setCarbohydrates] = useState('');
  const [selectedAlternativeList, setSelectedAlternativeList] = useState([]);
  const [alternativeList, setAlternativeList] = useState([]);

  useEffect(() => {
    setName(initialValue?.name || '');
    setUnitType(initialValue?.unit_type || '');
    setCalories(initialValue?.calories || '');
    setFat(initialValue?.fat || '');
    setProtein(initialValue?.protein || '');
    setCarbohydrates(initialValue?.carbohydrates || '');

    if (initialValue) {
      const alternativeList = ingredientsData.filter(ingredient => ingredient.id !== initialValue.id);
      setAlternativeList(alternativeList);

      if (initialValue.alternatives) {
        setSelectedAlternativeList(initialValue.alternatives);
      }
    } else {
      setAlternativeList(ingredientsData);
    }
  }, [isOpen, initialValue, ingredientsData]);

  const title = initialValue ? 'Edit ingredient' : 'Add new ingredient';

  const changeNameHandler = (event) => {
    setName(event.target.value);
  };

  const changeUnitTypeHandler = (event) => {
    setUnitType(event.target.value);
  };

  const onSaveHandler = () => {
    const trimmedName = name.trim();
    if (!trimmedName) {
      return toast.error('Name is required', {
        position: 'bottom-right',
        duration: 3000,
      });
    }

    if (!unitType) {
      return toast.error('Unit type is required', {
        position: 'bottom-right',
        duration: 3000,
      }); 
    }

    // Check if there is an ingredient with the same name and unit type
    const existenceIngredient = ingredientsData.find(ingredient => {
      if (ingredient.name !== trimmedName) {
        return false;
      }

      return ingredient.unit_type === unitType && initialValue?.id !== ingredient.id;
    });

    if (existenceIngredient) {
      return toast.error('Ingredient with this name and unit type already exists', {
        position: 'bottom-right',
        duration: 3000,
      }); 
    }

    const alternatives = selectedAlternativeList.length ? selectedAlternativeList : Array.from(selectedAlternativeList.keys());

    const ingredientData = {
      name: trimmedName,
      unit_type: unitType,
      alternatives,
    };

    if (calories) {
      ingredientData.calories = calories;
    }

    if (fat) {
      ingredientData.fat = fat;
    }

    if (protein) {
      ingredientData.protein = protein;
    }

    if (carbohydrates) {
      ingredientData.carbohydrates = carbohydrates;
    }

    if (initialValue) {
      ingredientData.id = initialValue.id;
      updateIngredient(ingredientData);
    } else {
      addIngredient(ingredientData);
    }

    onClose();
  };

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} onSave={onSaveHandler} title={title}>
      <div className={styles.wrapper}>
        <input 
          className={styles.input} 
          onChange={changeNameHandler}
          placeholder="Ingredient name"
          value={name}
        />
        <select 
          className={styles.select} 
          onChange={changeUnitTypeHandler}
          value={unitType}
        >
          <option value="" disabled>Select unit type</option>
          {unitTypes.map((unitTypeData) => (
            <option key={unitTypeData.value} value={unitTypeData.value}>
              {unitTypeData.label}
            </option>
          ))}
        </select>
        {unitType && unitType !== UNIT_TYPE_WITHOUT_AMOUNT && (
          <>
            <div className={styles.optional}>Optional:</div>
            <div className={styles.nutrients}>
              <input 
                className={styles.input} 
                onChange={({ target }) => setCalories(target.value)}
                type="number"
                placeholder="Kkal"
                value={calories}
              />
              <input 
                className={styles.input} 
                onChange={({ target }) => setFat(target.value)}
                type="number"
                placeholder="Fat"
                value={fat}
              />
              <input 
                className={styles.input} 
                onChange={({ target }) => setProtein(target.value)}
                type="number"
                placeholder="Protein"
                value={protein}
              />
              <input 
                className={styles.input} 
                onChange={({ target }) => setCarbohydrates(target.value)}
                type="number"
                placeholder="Carbohydrates"
                value={carbohydrates}
              />
            </div>
          </>
        )}
        <div className={styles.alternative}>Alternative ingredients</div>
        <AlternativesSelect 
          initialIngredients={initialValue?.alternatives} 
          onChange={setSelectedAlternativeList}
          ingredientsData={alternativeList}
        />
      </div>
    </ModalWrapper>
  );
};

const mapStateToProps = (store) => ({
  ingredientsData: store.ingredient.ingredientsData,
});

const mapDispatchToProps = {
  addIngredient,
  updateIngredient,
};

export default connect(mapStateToProps, mapDispatchToProps)(IngredientModal);