import toast from 'react-hot-toast';

import { ingredientAPI } from '../api/ingredient';
import { actionWrapper } from './actionWrapper';

export const SET_INGREDIENTS = 'SET_INGREDIENTS';
export const DELETE_INGREDIENT = 'DELETE_INGREDIENT';

export const getIngredients = () => actionWrapper(async () => {
  const ingredientsData = await ingredientAPI.getIngredients();

  return {
    type: SET_INGREDIENTS,
    payload: ingredientsData,
  };
});

export const addIngredient = (ingredientData) => actionWrapper(async (dispatch) => {
  await ingredientAPI.addIngredient(ingredientData);
  dispatch(getIngredients());
});

export const updateIngredient = (ingredientData) => actionWrapper(async (dispatch) => {
  await ingredientAPI.updateIngredient(ingredientData);
  dispatch(getIngredients());
});

export const deleteIngredient = (id) => actionWrapper(async () => {
  await ingredientAPI.deleteIngredient(id);

  return {
    type: DELETE_INGREDIENT,
    payload: id,
  };
});

export const calculateNutrients = () => actionWrapper(async (dispatch) => {
  await ingredientAPI.calculateNutrients();
  toast.success('Success', {
    position: 'bottom-right',
    duration: 3000,
  });
  dispatch(getIngredients());
});