import React from 'react';
import { connect } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import ScrollToTop from '../../hoc/ScrollToTop';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Preloader from '../../components/Preloader/Preloader';
import Questioner from '../Questioner/Questioner';
import QuestionerDetails from '../QuestionerDetails/QuestionerDetails';
import ChatGPTUsers from '../ChatGPTUsers/ChatGPTUsers';
import ChatGPTDetails from '../ChatGPTDetails/ChatGPTDetails';
import RemindMe from '../RemindMe/RemindMe';
import StrictMenu from '../StrictMenu/StrictMenu';
import Dishes from '../Dishes/Dishes';
import AddDish from '../AddDish/AddDish';
import EditDish from '../EditDish/EditDish';
import Ingredients from '../Ingredients/Ingredients';
import AddIngredient from '../AddIngredient/AddIngredient';
import MobileUsers from '../MobileUsers/MobileUsers';
import MobileUserDetails from '../MobileUserDetails/MobileUserDetails';
import MobileChatGPTUsers from '../MobileChatGPTUsers/MobileChatGPTUsers';
import MobileChatGPTDetails from '../MobileChatGPTDetails/MobileChatGPTDetails';
import ChatGPTTests from '../ChatGPTTests/ChatGPTTests';
import ChatGPTTestDetails from '../ChatGPTTestDetails/ChatGPTTestDetails';
import Settings from '../Settings/Settings';
import PageNotFound from '../PageNotFound/PageNotFound';

import Login from '../Login/Login';
import styles from './App.module.css';

function App (props) {
  const { isFetching, userData } = props;

  const renderCommonRoutes = () => (
    <Route path="*" Component={PageNotFound}/>
  );

  if (!userData) {
    return (
      <div className={styles.wrapper}>
        <ScrollToTop />
        <Toaster />
        <Routes>
          <Route exact path="/" Component={Login}/>
          {renderCommonRoutes()}
        </Routes>
        <Footer />
      </div>
    );
  }

  return (
    <div className={styles.authWrapper}>
      {isFetching && <Preloader/>}
      <Toaster />
      <ScrollToTop />
      <Header />
      <Routes>
        <Route exact path="/" Component={Questioner} />
        <Route exact path="/questioner/:id" Component={QuestionerDetails} />
        <Route exact path="/chat-gpt" Component={ChatGPTUsers} />
        <Route exact path="/chat-gpt/:id" Component={ChatGPTDetails} />
        <Route exact path="/remind-me" Component={RemindMe} />
        <Route exact path="/strict-menu" Component={StrictMenu} />
        <Route exact path="/dishes" Component={Dishes} />
        <Route exact path="/add-dish" Component={AddDish} />
        <Route exact path="/edit-dish/:id" Component={EditDish} />
        <Route exact path="/ingredients" Component={Ingredients} />
        <Route exact path="/add-ingredient" Component={AddIngredient} />
        <Route exact path="/mobile-users" Component={MobileUsers} />
        <Route exact path="/mobile-user/:id" Component={MobileUserDetails} />
        <Route exact path="/mobile-chat-gpt" Component={MobileChatGPTUsers} />
        <Route exact path="/mobile-chat-gpt/:id" Component={MobileChatGPTDetails} />
        <Route exact path="/chat-gpt-tests" Component={ChatGPTTests} />
        <Route exact path="/chat-gpt-test/:id" Component={ChatGPTTestDetails} />
        <Route exact path="/settings" Component={Settings} />
        {renderCommonRoutes()}
      </Routes>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isFetching: state.preloader.isFetching,
  userData: state.auth.userData,
});

export default connect(mapStateToProps)(App);