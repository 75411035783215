import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import cn from 'classnames';
import _ from 'lodash';

import { getIngredients } from '../../actions/ingredient';
import DishComponent from './DishComponent';
import styles from './DishForm.module.css';

const defaultComponent = {
  fakeId: Date.now(),
  name: '',
  instruction: '',
  ingredients: [],
};

const DishForm = (props) => {
  const {
    getIngredients, 
    ingredientsData, 
    initialValues = {}, 
    onSave,
  } = props;

  const [name, setName] = useState(initialValues?.name || '');
  const [mealName, setMealName] = useState(initialValues?.meal_name || '');
  const [serving, setServing] = useState(initialValues?.serving || '');
  const [components, setComponents] = useState(initialValues?.components || [defaultComponent]);

  useEffect(() => {
    getIngredients();
  }, []);

  const onSaveHandler = () => {
    const trimmedName = name.trim();
    if (!trimmedName) {
      return toast.error('Name is required', {
        position: 'bottom-right',
        duration: 3000,
      });
    }

    if (!mealName) {
      return toast.error('Meal name is required', {
        position: 'bottom-right',
        duration: 3000,
      });
    }

      // Check all data in components
    for (let component of components) {
      const {
        name,
        instruction,
        ingredients,
      } = component;

      if (!name || !instruction || _.isEmpty(ingredients)) {
        return toast.error('All fields in component is required', {
          position: 'bottom-right',
          duration: 3000,
        });
      }

      if (!ingredients?.length) {
        const ingredientsArray = [];
        ingredients.forEach((data) => {
          ingredientsArray.push(data);
        });

        component.ingredients = ingredientsArray;
      } 
    }

    const dishData = {
      name: trimmedName,
      meal_name: mealName,
      components,
      serving,
    };

    console.log('===dishData', onSave, dishData);

    onSave(dishData);
  };

  const onChangeComponentHandler = (componentData, index) => {
    components[index] = componentData;

    console.log('===ON CHANGE', components);
    setComponents([...components]);
  };

  const addComponent = () => {
    const newComponent = {
      ...defaultComponent,
      fakeId: Date.now(),
    };

    components.push(newComponent);
    setComponents([...components]);
  };

  const deleteComponent = (index) => {
    components.splice(index, 1);
    setComponents([...components]);
  };

  return (
    <div>
      <input 
        className={styles.input}
        placeholder="Name of dish"
        value={name}
        onChange={({ target }) => setName(target.value)}
      />
      <select 
        className={styles.select}
        onChange={({ target }) => setMealName(target.value)}
        value={mealName}
      >
        <option value="" disabled>Select meal name</option>
        <option value="breakfast">Breakfast</option>
        <option value="snack">Snack</option>
        <option value="lunch">Lunch</option>
        <option value="dinner">Dinner</option>
      </select>
      <div>
        <div className={styles.dishComponents}>Dish Components</div>
        {components.map((component, index) => (
          <div className={styles.componentBlock} key={component.fakeId} >
            <DishComponent 
              initialValues={component}
              onChange={componentData => onChangeComponentHandler(componentData, index)}
              ingredientsData={ingredientsData}
            />
            {components.length > 1 && (
              <button 
                className={cn(styles.saveButton, 'button', 'red-button')} 
                onClick={() => deleteComponent(index)}
              >
                Delete component
              </button>
            )}
          </div>
        ))}
        <button 
          className={cn(styles.saveButton, 'button', 'green-button')} 
          onClick={addComponent}
        >
          Add component
        </button>
      </div>
      <textarea 
        className={cn(styles.input, styles.serving)}
        placeholder="Serving"
        value={serving}
        onChange={({ target }) => setServing(target.value)}
      />

      <button 
        className={cn(styles.saveButton, 'button', 'blue-button')} 
        onClick={onSaveHandler}
      >
        Save
      </button>
    </div>
  );
};

const mapStateToProps = (store) => ({
  ingredientsData: store.ingredient.ingredientsData,
});

const mapDispatchToProps = {
  getIngredients,
};

export default connect(mapStateToProps, mapDispatchToProps)(DishForm);
