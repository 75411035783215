import { get, post, put, deleteById } from './api';

export const ingredientAPI = {
  getIngredients() {
    return get('admin/ingredient');
  },

  addIngredient(ingredientData) {
    return post('admin/ingredient', ingredientData);
  },

  updateIngredient(ingredientData) {
    return put('admin/ingredient', ingredientData);
  },

  deleteIngredient(id) {
    return deleteById('admin/ingredient', id);
  },

  calculateNutrients() {
    return put('admin/ingredient/calculate-nutrients');
  }
};
