import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { getIngredientAmountString } from '../../utils/functions';
import { getDishes, deleteDish } from '../../actions/dish';
import styles from './Dishes.module.css';

const Dishes = (props) => {
  const { 
    getDishes, 
    deleteDish, 
    dishesData, 
  } = props;

  useEffect(() => {
    getDishes();
  }, []);

  const deleteHandler = (id) => {
    const confirmResult = window.confirm('Are you sure?');

    if (confirmResult) {
      deleteDish(id);
    }
  };

  const renderComponentName = (dishComponents, componentName) => {
    if (dishComponents.length === 1) {
      return null;
    }

    return (
      <div className={styles.componentName}>
        {componentName}:
      </div>
    );
  };

  const renderIngredientsList = (dishComponents) => {
    return dishComponents.map((component, index) => {

      return (
        <div key={index} className={styles.ingredientsBlock}>
          {renderComponentName(dishComponents, component.name)}
          {component.ingredients.map(ingredient => (
            <div key={ingredient.id} className={styles.ingredientItem}>
              {getIngredientAmountString(ingredient, ingredient.amount, ingredient.is_required)}
            </div>
          ))}
        </div>
      );
    });
  };

  const renderInstructions = (dishComponents, serving) => {
    const instructions = dishComponents.map((component, index) => (
      <div key={index} className={styles.instructionItem}>
        {renderComponentName(dishComponents, component.name)}
        {component.instruction}
      </div>
    ));

    if (serving) {
      instructions.push(
        <div key="serving" className={styles.instructionItem}>
          <div className={styles.componentName}>
            Serving:
          </div>
          {serving}
        </div>
      );
    }

    return instructions;
  };

  return (
    <div className="wrapper">
      <h1>
        Dishes
      </h1>
      <div className={styles.tools}>
        <select className={styles.dishTypeSelect}>
          <option value="">All</option>
          <option value="breakfast">Breakfast</option>
          <option value="snack">Snack</option>
          <option value="lunch">Lunch</option>
          <option value="dinner">Dinner</option>
        </select>
        <a className={styles.addDish} href="/add-dish">
          Add dish
        </a>
      </div>
      <table className={cn(styles.table, 'table')}>
        <thead>
        <tr>
          <th>Name</th>
          <th>Meal Name</th>
          <th>Instructions</th>
          <th>Ingredients</th>
          <th>Controls</th>
        </tr>
        </thead>
        <tbody>
        {dishesData?.map((dishData) => (
          <tr key={dishData.id}>
            <td>                
              {dishData.name}
            </td>
            <td>
              {dishData.meal_name}
            </td>
            <td>
              {renderInstructions(dishData.components, dishData.serving)}
            </td>
            <td>
              {renderIngredientsList(dishData.components)}
            </td>
            <td className="controls">
              <Link to={`/edit-dish/${dishData.id}`} className="button green-button">
                Edit
              </Link>
              <button className="button red-button" onClick={() => deleteHandler(dishData.id)}>
                Delete
              </button>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (store) => ({
  dishesData: store.dish.dishesData,
});

const mapDispatchToProps = {
  getDishes,
  deleteDish,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dishes);