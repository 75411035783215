import {
  SET_INGREDIENTS,
  DELETE_INGREDIENT,
} from '../actions/ingredient';

let initialState = {
  ingredientsData: [],
};

const ingredient = (state = initialState, action) => {
  switch (action.type) {
    case SET_INGREDIENTS:
      return {
        ...state,
        ingredientsData: action.payload,
      };

    case DELETE_INGREDIENT: {
      const { ingredientsData } = state;
      const newIngredientsData = ingredientsData.filter((ingredientData) => {
        return ingredientData.id !== action.payload;
      });

      return {
        ...state,
        ingredientsData: newIngredientsData,
      };
    }

    default:
      return state;
  }
};

export default ingredient;
