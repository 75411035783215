import React, { useState, useEffect } from 'react';
import cn from 'classnames';

// import { arrayToMap } from '../../utils/functions';
import IngredientsSelect from '../IngredientsSelect/IngredientsSelect';
import styles from './DishForm.module.css';

const DishComponent = (props) => {
  const { 
    initialValues = new Map(), 
    onChange,
    ingredientsData,
  } = props;

  const [name, setName] = useState(initialValues?.name || '');
  const [ingredients, setIngredients] = useState(initialValues?.ingredients || []);
  const [instruction, setInstruction] = useState(initialValues?.instruction || '');

  console.log('===COMPONENT INGREDIENTS', ingredients);

  useEffect(() => {
    const componentData = {
      name,
      ingredients,
      instruction,
    };

    console.log('===COMPONENT DATA Data', componentData);

    onChange(componentData);
  }, [name, ingredients, instruction]);

  return (
    <div>
      <input 
        className={styles.input} 
        placeholder="Name of component"
        value={name}
        onChange={({ target }) => setName(target.value)}
      />
      <textarea 
        className={cn(styles.input, styles.textarea)}
        placeholder="Instruction"
        value={instruction}
        onChange={({ target }) => setInstruction(target.value)}
      />
      <div className={styles.dishComponents}>
        Ingredients
      </div>
      <div className={styles.ingredientsBlock}>
        <IngredientsSelect
          initialIngredients={ingredients}
          ingredientsData={ingredientsData} 
          onChange={setIngredients}
        />
      </div>
    </div>
  );
};

export default DishComponent;
