import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { 
  getChatGPTTestDetails, 
  saveTest, 
  runTest,
  clearTestAnswers,
 } from '../../actions/mobileUser';
import styles from './ChatGPTTestDetails.module.css';

const ChatGPTTestDetails = (props) => {
  const {
    getChatGPTTestDetails,
    saveTest,
    runTest,
    clearTestAnswers,
    testDetails,
  } = props;

  console.log('===testDetails', testDetails);

  const [promptsData, setPromptsData] = useState();
  const [type, setType] = useState('');

  // console.log('===promptsData', promptsData);
  const { id } = useParams();

  useEffect(() => {
    getChatGPTTestDetails(id);
  }, []);

  useEffect(() => {
    if (testDetails?.prompt) {
      setType(testDetails.type);
      setPromptsData(testDetails.prompt);
    }
  }, [testDetails]);

  const changePromptHandler = (index, { value, name }) => {
    promptsData[index][name] = value;
    setPromptsData([...promptsData]);
  };

  const deletePromptHandler = (index) => {
    promptsData.splice(index, 1);
    setPromptsData([...promptsData]);
  };

  const addPromptHandler = (afterIndex) => {
    const defaultPrompt = { role: 'user', content: '' };
    promptsData.splice(afterIndex + 1, 0, defaultPrompt);
    setPromptsData([...promptsData]);
  };

  const saveHandler = () => {
    const promptData = {
      id,
      type,
      prompt: promptsData,
    };

    saveTest(promptData);
  };

  if (!promptsData?.length) {
    return false;
  }

  return (
    <div className={styles.wrapper}>
      <h1>
        ChatGPT test
      </h1>
      <div>
        <label className={styles.roleBlock}>
          <span>Type: </span>
          <input 
            className={styles.input}
            value={type} 
            onChange={({ target }) => setType(target.value)} 
          />
        </label>
        {promptsData.map((prompt, index) => (
          <div key={index} className={styles.promptItem}>
            <label className={styles.roleBlock}>
              <span>Role: </span>
              <select 
                className={styles.select} 
                onChange={({ target }) => changePromptHandler(index, target)} 
                value={prompt.role}
                name="role"
              >
                <option value="user">User</option>
                <option value="assistant">Assistant</option>
                <option value="system">System</option>
              </select>
            </label>
            <div>
              <label>
                <div>Prompt:</div>
                <textarea 
                  className={styles.textarea} 
                  value={prompt.content}
                  name="content"
                  onChange={({ target }) => changePromptHandler(index, target)} 
                />
              </label>
            </div>
            <div className={styles.promptButtons}>
              <button 
                className="button green-button"
                onClick={() => addPromptHandler(index)}
              >
                Add Prompt
              </button>
              <button 
                className="button red-button" 
                onClick={() => deletePromptHandler(index)}
              >
                Delete Prompt
              </button>
            </div>
          </div>
        ))}
        <div className={styles.savePrompt}>
          <button 
            className="button blue-button" 
            onClick={saveHandler}
          >
            Save Prompt
          </button>
          <button 
            className="button yellow-button" 
            onClick={() => runTest(id)}
          >
            Run Prompt
          </button>
          <button 
            className="button red-button"
            onClick={() => clearTestAnswers(id)}
          >
            Clear Answers
          </button>
        </div>
      </div>
      <div>
        <div className={styles.answersTitle}>Answers</div>
        <div className={styles.answersBlock}>
          {testDetails.variants?.map(variant => {
            return variant.answers?.map(answer => (
              <div key={answer.id} className={styles.answerItem}>
                {answer.answer}
              </div>
            ));
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  testDetails: state.mobileUser.chatGPTTestDetails,
});

const mapDispatchToProps = {
  getChatGPTTestDetails,
  saveTest,
  runTest,
  clearTestAnswers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatGPTTestDetails);
