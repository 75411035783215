export const UNIT_TYPE_WITHOUT_AMOUNT = 'without_amount';
export const UNIT_TYPE_AMOUNT = 'amount';
export const UNIT_TYPE_GRAM = 'gram';
export const UNIT_TYPE_CUP = 'cup';
export const UNIT_TYPE_TABLESPOON = 'tablespoon';
export const UNIT_TYPE_TEASPOON = 'teaspoon';

export const unitTypes = [
  {
    label: 'Without amount',
    value: UNIT_TYPE_WITHOUT_AMOUNT,
  },
  {
    label: 'Amount',
    value: UNIT_TYPE_AMOUNT,
  },
  {
    label: 'Gram',
    value: UNIT_TYPE_GRAM,
  },
  {
    label: 'Cup',
    value: UNIT_TYPE_CUP,
  },
  {
    label: 'Tablespoon',
    value: UNIT_TYPE_TABLESPOON,
  },
  {
    label: 'Teaspoon',
    value: UNIT_TYPE_TEASPOON,
  },
];

export const unitTypeLabels = unitTypes.reduce((labels, unitTypeData) => {
  labels[unitTypeData.value] = unitTypeData.label;
  return labels;
}, {});