import { dishAPI } from '../api/dish';
import { actionWrapper } from './actionWrapper';

export const SET_DISHES = 'SET_DISHES';
export const SET_CURRENT_DISH = 'SET_CURRENT_DISH';
export const DELETE_DISH = 'DELETE_DISH';

export const getDishes = () => actionWrapper(async () => {
  const dishesData = await dishAPI.getDishes();

  return {
    type: SET_DISHES,
    payload: dishesData,
  };
});

const setCurrentDish = (dishData) => {
  return {
    type: SET_CURRENT_DISH,
    payload: dishData,
  };
};

export const getDishById = (id) => actionWrapper(async () => {
  const dishData = await dishAPI.getDishById(id);

  return setCurrentDish(dishData);
});

export const clearCurrentDish = () => {
  return setCurrentDish(null);
};

export const addDish = (dishData, callback) => actionWrapper(async (dispatch) => {
  await dishAPI.addDish(dishData);
  dispatch(getDishes());
  callback?.();
});

export const updateDish = (dishData, callback) => actionWrapper(async (dispatch) => {
  await dishAPI.updateDish(dishData);
  dispatch(getDishes());
  callback?.();
});

export const deleteDish = (id) => actionWrapper(async () => {
  await dishAPI.deleteDish(id);

  return {
    type: DELETE_DISH,
    payload: id,
  };
});