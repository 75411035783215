import { get, getById, post, put, deleteById } from './api';

export const dishAPI = {
  getDishes() {
    return get('admin/dish');
  },

  getDishById(id) {
    return getById('admin/dish', id);
  },

  addDish(dishData) {
    return post('admin/dish', dishData);
  },

  updateDish(ingredientData) {
    return put('admin/dish', ingredientData);
  },

  deleteDish(id) {
    return deleteById('admin/dish', id);
  },
};
